.drive-footer__menu-link { 
@apply text-white;
@apply text-xs;
@apply leading-4;
@apply font-medium;
@screen md { 
@apply leading-4;
@apply text-sm;
}

}
