.drive-footer__copyrights { 
@apply border-t;
@apply border-gray-500;
@apply text-gray-500;
@apply pt-2;
@apply text-xs;
@screen md { 
@apply pb-0;
@apply border-t-0;
}

  &__abn { 
    @apply pl-2;
  }
}
