.drive-footer__bottom-menu { 
  &__container { 
    @apply flex;
    @apply flex-wrap;
    @apply justify-between;
  }

  &__item { 
    @screen md { 
      @apply pl-8;
    }
  }
  &__link { 
    @apply text-white;
    @apply text-sm;
    @apply leading-4;
    @apply font-medium;
  }
}
